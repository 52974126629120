<template>
  <div class="block-content">
    <div>
      <h3 class="mb-2">Add New Invoice</h3>
      <p>
        Create a new invoice by filling out the form below. If you add any reads, these will also be visible in the Readings tab. The same for
        consumption, this can be viewed in the consumption tab.
      </p>

      <div>
        <div class="row push">
          <div class="col-lg-3"></div>
          <div class="col-lg-8 col-xl-5">
            <FormGroup
              id="simulated"
              v-model="form.simulated"
              type="select"
              label="Simulated"
              description="If the data below is estimated, and not from an actual invoice, you can mark this as simulated so it can be viewed separately on reports."
              :options="[
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ]"
            />
            <div v-for="value in invoiceValues" :key="value.key">
              <FormGroup
                :id="value.key"
                v-model="form.values[value.key]"
                :label="value.friendly"
                :type="formTypeMap[value.valueType]"
                :error="validationErrors[value.key]"
                @input="input => onInputValue(input, value.key)"
              />
            </div>
            <FormGroup
              id="supplierId"
              v-model="form.supplierId"
              :options="sortedSuppliers.map(s => ({ label: s.name, value: s._id }))"
              label="Supplier Name"
              type="select"
            />
            <div>
              <div class="form-group">
                <label>Invoice PDF</label>
                <FileUpload v-model="form.file" @reset="onClearFile" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="['electricity', 'gas', 'water'].includes(account.type)">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h3 class="mb-0">Rates</h3>
        </div>
        <p>All the rates related to this invoice. Changing the consumption or cost of a rate will automatically update the totals table below.</p>

        <table v-if="form.rates.length > 0" class="table mb-4">
          <tbody v-for="(rate, index) in form.rates" :id="`rate-${rate.id}`" :key="rate.id">
            <tr>
              <th class="bg-light" colspan="4">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="font-size-h5">{{ rate.rateName }}</span>
                  <span>
                    <button
                      v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rate.type) && rate.startRead === null"
                      class="btn btn-alt-primary btn-sm mr-3"
                      @click="onClickAddReads(rate)"
                    >
                      <i class="fa fa-tachometer-alt mr-1"></i> Add Reads
                    </button>
                    <button
                      v-if="rate.startRead !== null || rate.startRead !== null"
                      class="btn btn-alt-danger btn-sm mr-3"
                      @click="onClickRemoveReads(rate)"
                    >
                      <i class="fa fa-tachometer-alt mr-1"></i> Remove Reads
                    </button>
                    <button
                      v-if="rate.startDate === null && rate.startDate === null"
                      class="btn btn-alt-primary btn-sm mr-3"
                      @click="onClickAddDates(rate)"
                    >
                      <i class="fa fa-calendar-alt mr-1"></i> Add Dates
                    </button>
                    <button
                      v-if="rate.startDate !== null || rate.startDate !== null"
                      class="btn btn-alt-danger btn-sm mr-3"
                      @click="onClickRemoveDates(rate)"
                    >
                      <i class="fa fa-calendar-alt mr-1"></i> Remove Dates
                    </button>
                    <button class="btn btn-alt-danger btn-sm" @click="onClickRemoveRate(rate, index)">
                      <span><i class="fa fa-ban mr-1"></i> Remove</span>
                    </button>
                  </span>
                </div>
              </th>
            </tr>
            <tr>
              <td><strong>Type</strong></td>
              <td>{{ getFriendlyRateType(rate.type) }}</td>
              <td>
                <FormItem
                  :id="`consumption-${rate.id}`"
                  type="select"
                  :value="rate.type"
                  :options="rateTypes"
                  @input="input => onInputRate(input, index, 'type')"
                />
              </td>
            </tr>
            <tr v-if="rate.startDate !== null || rate.endDate !== null">
              <td><strong>Start Date</strong></td>
              <td>
                {{ rate.friendlyStartDate }}<br />
                <span class="text-info">{{ rate.friendlyCalculatedStartDate }}</span>
              </td>
              <td>
                <FormItem
                  :id="`startDate-${rate.id}`"
                  type="datePicker"
                  :value="rate.startDate"
                  @input="input => onInputRate(input, index, 'startDate')"
                />
              </td>
            </tr>
            <tr v-if="rate.startDate !== null || rate.endDate !== null">
              <td>
                <strong>End Date</strong>
              </td>
              <td>
                {{ rate.friendlyEndDate }}<br />
                <span class="text-info">{{ rate.friendlyCalculatedEndDate }}</span>
              </td>
              <td>
                <FormItem :id="`endDate-${rate.id}`" type="datePicker" :value="rate.endDate" @input="input => onInputRate(input, index, 'endDate')" />
              </td>
            </tr>
            <tr v-if="rate.type !== 'tax'">
              <td><strong>Consumption</strong></td>
              <td>
                {{ rate.friendlyConsumption }}<br />
                <span class="text-info">{{ rate.friendlyCalculatedConsumption }}</span>
              </td>
              <td>
                <FormItem
                  :id="`consumption-${rate.id}`"
                  type="number"
                  step="0.01"
                  :value="rate.consumption"
                  @input="input => onInputRate(input, index, 'consumption')"
                />
              </td>
            </tr>
            <tr v-if="rate.unitRate !== null">
              <td><strong>Unit Rate</strong></td>
              <td>
                {{ rate.friendlyUnitRate }}<br /><span class="text-info">{{ rate.friendlyCalculatedUnitRate }}</span>
              </td>
              <td>
                <FormItem
                  :id="`unitRate-${rate.id}`"
                  type="number"
                  step="0.01"
                  :value="rate.unitRate"
                  @input="input => onInputRate(input, index, 'unitRate')"
                />
              </td>
            </tr>
            <tr>
              <td><strong>Cost</strong></td>
              <td>
                {{ rate.cost }}<br /><span class="text-info">{{ rate.calculatedCost }}</span>
              </td>
              <td>
                <FormItem :id="`cost-${rate.id}`" type="number" step="0.01" :value="rate.cost" @input="input => onInputRate(input, index, 'cost')" />
              </td>
            </tr>
            <tr v-if="rate.startRead !== null || rate.endRead !== null">
              <td :style="{ 'padding-top': '33px' }">
                <strong>Start Read</strong>
              </td>
              <td :style="{ 'padding-top': '33px' }">
                {{ rate.friendlyStartRead }}
                <span v-if="rate.friendlyStartReadType">({{ rate.friendlyStartReadType }})</span><br />
                <span class="text-info">
                  {{ rate.friendlyCalculatedStartRead }}
                  <span v-if="rate.friendlyCalculatedStartReadType">({{ rate.friendlyCalculatedStartReadType }})</span>
                </span>
              </td>
              <td>
                <div class="row">
                  <div :class="displayRegister ? 'col-md-6' : 'col-md-8'">
                    <div class="form-group mb-0">
                      <label> Value </label>
                      <div class="input-group">
                        <FormItem
                          :id="`startRead-${rate.id}`"
                          type="number"
                          min="0"
                          step="0.01"
                          placeholder="Value"
                          :value="rate.startRead"
                          @input="input => onInputRate(input, index, 'startRead')"
                        />
                        <div class="input-group-append" :class="displayRegister ? '' : 'w-25'">
                          <FormItem
                            :id="`startReadUnits-${rate.id}`"
                            v-model="rate.startReadUnit"
                            :options="unitOptions"
                            type="select"
                            :is-alt="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div :class="displayRegister ? 'col-md-3' : 'col-md-4'">
                    <label> Type </label>
                    <FormItem
                      :id="`startReadType-${rate.id}`"
                      type="text"
                      placeholder="Type"
                      :value="rate.startReadType"
                      @input="input => onInputRate(input, index, 'startReadType')"
                    />
                  </div>
                  <div v-if="displayRegister" class="col">
                    <label> Register </label>
                    <FormItem
                      :id="`endReadRegister-${rate.id}`"
                      type="select"
                      placeholder="Register"
                      :value="rate.endReadRegister"
                      :options="filteredRegisterIds"
                      @input="input => onInputRate(input, rate.id, 'endReadRegister')"
                    />
                  </div>
                </div>
              </td>
              <td></td>
            </tr>
            <tr v-if="rate.startRead !== null || rate.endRead !== null">
              <td :style="{ 'padding-top': '33px' }">
                <strong>End Read</strong>
              </td>
              <td :style="{ 'padding-top': '33px' }">
                {{ rate.friendlyEndRead }}
                <span v-if="rate.friendlyEndReadType">({{ rate.friendlyEndReadType }})</span><br />
                <span class="text-info">
                  {{ rate.friendlyCalculatedEndRead }}
                  <span v-if="rate.friendlyCalculatedEndReadType">({{ rate.friendlyCalculatedEndReadType }})</span>
                </span>
              </td>
              <td>
                <div class="row">
                  <div :class="displayRegister ? 'col-md-6' : 'col-md-8'">
                    <label> Value </label>

                    <div class="form-group mb-0">
                      <div class="input-group">
                        <FormItem
                          :id="`endRead-${rate.id}`"
                          type="number"
                          min="0"
                          step="0.01"
                          placeholder="Value"
                          :value="rate.endRead"
                          @input="input => onInputRate(input, index, 'endRead')"
                        />
                        <div class="input-group-append" :class="displayRegister ? '' : 'w-25'">
                          <FormItem :id="`endReadUnits-${rate.id}`" v-model="rate.endReadUnit" :options="unitOptions" type="select" :is-alt="true" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div :class="displayRegister ? 'col-md-3' : 'col-md-4'">
                    <label> Type </label>
                    <FormItem
                      :id="`endReadType-${rate.id}`"
                      type="text"
                      placeholder="Type"
                      :value="rate.endReadType"
                      class="mb-0"
                      @input="input => onInputRate(input, index, 'endReadType')"
                    />
                  </div>
                  <div v-if="displayRegister" class="col-md-3">
                    <label> Register </label>
                    <FormItem
                      :id="`endReadRegister-${rate.id}`"
                      type="select"
                      placeholder="Register"
                      :value="rate.endReadRegister"
                      :options="filteredRegisterIds"
                      @input="input => onInputRate(input, rate.id, 'endReadRegister')"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <button type="button" class="btn btn-alt-primary btn-block mb-3" @click.prevent="onClickAddRate">
          <span>Add Rate</span>
        </button>

        <h3 class="mb-2">Totals</h3>
        <p>
          The below table are all the totals related to this invoice.
          <strong
            >Editing a total will not automatically update any related totals (e.g. updating "Total Tax" will not update "Total Cost"). These values
            will be used for reporting, so please ensure they are all correct.</strong
          >
        </p>

        <table class="table table-striped mb-4">
          <thead>
            <tr>
              <th>Field</th>
              <th>Current Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="value in invoiceTotals" :key="value.key">
              <td class="font-w600">{{ value.friendly }}</td>
              <td class="text-info font-w600">
                <FormItem
                  :id="value.key"
                  :type="value.formType"
                  :options="[]"
                  :value="form.values[value.key]"
                  @input="input => onInputValue(input, value.key)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="account.type === 'waste'">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h3 class="mb-0">Consumption Values</h3>
        </div>
        <p>Enter all the invoice consumption values.</p>

        <table class="table table-striped mb-4">
          <thead>
            <tr>
              <th>Field</th>
              <th>Current Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(category, idx) in wasteCategories.filter(c => wasteConsumption[c.name])" :key="category.name">
              <td class="font-w600">{{ category.name }}</td>
              <td class="text-info font-w600">
                <div class="input-group">
                  <FormItem :id="`consumption-value-${idx}`" v-model="wasteConsumption[category.name].value" class="col-xl-3 mr-3" placeholder=" " />
                  <FormItem
                    :id="`consumption-unit-${idx}`"
                    v-model="wasteConsumption[category.name].unit"
                    class="col-xl-3"
                    type="select"
                    :placeholder="`Select a unit`"
                    :options="[
                      { label: 'kg', value: 'kg' },
                      { label: 'tonnes', value: 'tonnes' }
                    ]"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <h3 class="mb-0">Costs Values</h3>
        </div>
        <p>Enter all the invoice cost values (if applicable).</p>

        <table class="table table-striped mb-4">
          <thead>
            <tr>
              <th>Field</th>
              <th>Current Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="value in invoiceCostTotals" :key="value.key">
              <td class="font-w600">{{ value.friendly }}</td>
              <td class="text-info font-w600">
                <FormItem :id="value.key" :type="value.formType" :value="form.values[value.key]" @input="input => onInputValue(input, value.key)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <button type="button" class="btn btn-primary btn-block mb-4" @click.prevent="onClickSubmit">
        <span v-if="!loading">Create Invoice</span>
        <span v-else><i class="fa fa-spinner fa-spin"></i> Creating Invoice...</span>
      </button>
    </div>
    <ConfirmModal
      :open="!!modals.addRate"
      title="Add Rate"
      text="Please enter/select a rate name"
      lg-size
      @close="modals.addRate = false"
      @submit="onConfirmRateModal"
    >
      <AddRateForm v-model="modals.addRate" :register-ids="filteredRegisterIds" :unit-options="unitOptions" />
    </ConfirmModal>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import { calculateTotals, invoiceTotalKeys, rateTypes } from '../lib/invoice';

import AddRateForm from './AddRateForm';
import ConfirmModal from './ConfirmModal';
import FileUpload from './FileUpload';
import FormGroup from './FormGroup';
import FormItem from './FormItem';
import { prepareInvoiceConsumptionForDB } from '../lib/consumption';

export default {
  name: 'InvoiceCreateForm',
  components: {
    AddRateForm,
    ConfirmModal,
    FileUpload,
    FormGroup,
    FormItem
  },
  props: {
    account: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        simulated: false,
        rates: [],
        values: {
          meterPointNumber: ''
        },
        file: null,
        fileName: ''
      },
      wasteConsumption: {},
      formTypeMap: {
        price: 'number',
        date: 'datePicker',
        string: 'text'
      },
      invoiceKeysMap: [],
      rateTypes: [...rateTypes],
      modals: {
        addRate: false
      }
    };
  },
  computed: {
    ...mapGetters({
      suppliers: 'supplier/suppliers',
      invoiceKeys: 'invoiceTemplate/invoiceKeys',
      validationErrors: 'invoice/validationErrors',
      loading: 'invoice/loading',
      wasteCategories: 'account/wasteCategories',
      units: 'util/units'
    }),
    displayRegister() {
      return this.filteredRegisterIds.length > 1;
    },
    filteredRegisterIds() {
      return [...new Set([...(this.account.registerIds || []), '1'])].map(id => ({ value: id, label: id }));
    },
    unitOptions() {
      return this.units.filter(u => u.types.includes(this.account.type)).map(u => ({ label: u.name, value: u.value }));
    },
    sortedSuppliers() {
      let suppliers = [...this.suppliers];

      suppliers.sort((a, b) => a?.name?.localeCompare(b.name));

      return suppliers;
    },
    invoiceValues() {
      return this.invoiceKeys.filter(value => value.types.includes(this.account.type) && !invoiceTotalKeys.includes(value.key));
    },
    invoiceTotals() {
      return this.invoiceKeys.filter(value => value.types.includes(this.account.type) && invoiceTotalKeys.includes(value.key));
    },
    invoiceCostTotals() {
      return this.invoiceTotals.filter(value => ['totalLevy', 'totalTax', 'netTotalCost', 'totalCost'].includes(value.key));
    }
  },
  async mounted() {
    this.invoiceKeys.forEach(i => i.types.includes(this.account.type) && (this.form.values[i.key] = i.valueType === 'price' ? 0 : ''));
    this.$set(this.form.values, 'meterPointNumber', this.account.meterPointNumber);
    this.$set(this.form.values, 'meterSerialNumber', this.account.meterSerialNumber);
    this.form.supplierId = this.account.supplierId;
    this.recalculateTotals();

    if (this.account.type === 'waste') {
      await this.getWasteCategories();

      this.wasteCategories.forEach(category => {
        this.$set(this.wasteConsumption, category.name, { value: null, unit: 'kg' });
      });
    }
  },
  methods: {
    ...mapActions({
      createInvoice: 'invoice/create',
      confirmInvoice: 'invoice/confirmInvoice',
      deleteInvoice: 'invoice/remove',
      upload: 'invoice/manualUpload',
      getWasteCategories: 'account/getWasteCategories',
      createBulkConsumption: 'consumption/createBulk',
      convertManualValues: 'invoice/convertManualValues'
    }),
    getFriendlyRateType(type) {
      const rateType = this.rateTypes.find(rt => rt.value === type);

      if (rateType) return rateType.label;

      return 'N/A';
    },
    async onClickSubmit() {
      const { rates, values } = this.form;

      let s3KeyResponse = {};
      if (this.form.file) s3KeyResponse = await this.upload({ data: this.form.file.file });

      const invoiceData = {
        values,
        companyId: this.account.companyId,
        entityId: this.account.entityId,
        startTime: moment(),
        accountId: this.account._id,
        completed: true,
        isManual: true,
        type: this.account.type,
        rates,
        simulated: this.form.simulated,
        supplierId: this.form.supplierId
      };

      if (s3KeyResponse.s3Key) {
        invoiceData.s3Key = s3KeyResponse.s3Key;
        invoiceData.fileName = this.form.file.fileName;
      }

      const createdInv = await this.createInvoice({ data: invoiceData });

      if (!createdInv._id) return this.$toasted.error('Could not create invoice.');

      if (this.account.type === 'waste') {
        // Apportion waste consumption into months (if start and end date cover multiple months)

        const consumptionBase = {
          source: 'invoice',
          accountId: this.account._id,
          entityId: this.account.entityId,
          companyId: this.account.companyId,
          invoiceId: createdInv._id
        };

        const consumptionData = prepareInvoiceConsumptionForDB(
          consumptionBase,
          this.wasteConsumption,
          this.form.values.startDate,
          this.form.values.endDate
        );

        if (consumptionData.length > 0) {
          const addConsumption = await this.createBulkConsumption({ data: consumptionData });

          if (addConsumption.length !== consumptionData.length && addConsumption.every(c => c._id)) {
            this.deleteInvoice({ id: createdInv._id });
            this.$toasted.error('There was an issue adding consumption values.');
            return;
          }
        }
      }

      const confirmInv = await this.confirmInvoice({
        id: createdInv._id,
        data: { values, rates }
      });

      if (confirmInv._id) {
        s3KeyResponse.body
          ? this.$toasted.error('Saved invoice successfully but there was an error uploading the invoice file.')
          : this.$toasted.success('Saved invoice successfully.');

        this.$emit('completed');
      } else {
        this.deleteInvoice({ id: createdInv._id });
        this.$toasted.error('Could not create invoice.');
      }

      this.form = { values: {}, rates: [], file: null };
    },
    onInputValue(newValue, key) {
      this.form.values[key] = newValue;
    },
    onInputRate(newValue, index, ratePrefix) {
      this.form.rates[index][ratePrefix] = newValue;
      this.recalculateTotals();
    },
    recalculateTotals() {
      const totals = calculateTotals(this.form.rates, this.form.values);
      Object.assign(this.form.values, totals);
    },
    onClickAddRate() {
      const newRate = {
        rateName: 'New Rate',
        type: 'unitRate',
        consumption: 0,
        unitRate: 0,
        cost: 0,
        startRead: null,
        startReadType: null,
        endRead: null,
        endReadType: null,
        startDate: null,
        endDate: null,
        startReadUnit: this.unitOptions[0]?.value,
        endReadUnit: this.unitOptions[0]?.value
      };

      this.modals.addRate = newRate;
    },
    async onClickRemoveRate(rate, index) {
      this.form.rates.splice(index, 1);
      this.recalculateTotals();
    },
    onClickAddReads(rate) {
      rate.startRead = 0;
      rate.endRead = 0;
      rate.startReadType = 'E';
      rate.endReadType = 'E';
    },
    onClickAddDates(rate) {
      rate.startDate = moment().format('DD/MM/YYYY').toDate();
      rate.endDate = moment().format('DD/MM/YYYY').toDate();
    },
    onClickRemoveDates(rate) {
      rate.startDate = null;
      rate.endDate = null;
    },
    onClickRemoveReads(rate) {
      rate.startRead = null;
      rate.endRead = null;
      rate.startReadType = null;
      rate.endReadType = null;
    },
    async onConfirmRateModal() {
      const convertedValues = await this.convertManualValues({
        data: {
          rates: [this.modals.addRate, ...this.form.rates],
          values: this.form.values,
          invoiceKeys: this.invoiceKeys
        }
      });

      this.form.rates = convertedValues.rates;

      this.recalculateTotals();

      this.modals.addRate = false;
    },
    onClearFile() {
      this.form.file = null;
      this.form.s3Key = null;
      this.form.fileName = null;
    }
  }
};
</script>
